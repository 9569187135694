<template>
  <v-card>
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            Accounts
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-autocomplete v-model="selectedAdNetworks" :items="adNetworks" multiple label="Ad networks" clearable prepend-icon="stream">
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete v-model="selectedVerticals" :items="activeVerticals" item-text="name" item-value="id" multiple label="Verticals" 
              clearable prepend-icon="construction">
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete v-model="selectedCountries" :items="activeCountries" item-text="name" item-value="id" multiple label="Countries" 
              clearable prepend-icon="public">
            </v-autocomplete>
          </v-col>
          <v-col>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col>
            <v-autocomplete v-model="selectedLanguages" :items="activeLanguages" item-text="name" item-value="id" multiple label="Languages" 
              clearable prepend-icon="language">
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete v-model="selectedDomainTypes" :items="domainTypes" item-text="name" item-value="id" multiple label="Domain types" 
              clearable prepend-icon="domain">
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field v-model="accountNameSearch" label="Account name" clearable prepend-icon="text_snippet">
            </v-text-field>
          </v-col>
          <v-col>
            <v-btn class="mt-2" color="primary" v-on:click="show">Show</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-1"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`header.domains_csv`]="{ header }">
        <v-tooltip>
          <template v-slot:activator="{ on }">
            <v-icon small v-on="on">help</v-icon>
          </template>
          <span>The current fallback mapped domain for this account if available, and if not, the domains matched to campaigns</span>
        </v-tooltip>
        {{ header.text }}
      </template>

      <template v-slot:[`header.vertical`]="{ header }">
        <v-tooltip>
          <template v-slot:activator="{ on }">
            <v-icon small v-on="on">help</v-icon>
          </template>
          <span>Vertical, country, language and domain type from the CMS hosts data for the fallback mapped domain or a random campaign domain if no fallback is available</span>
        </v-tooltip>
        {{ header.text }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon :to="{ name: 'Account', params: { accountID: item.id }}">
          <v-icon small>edit</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'AccountsTable',

  data () {
    return {
      headers: [
        { text: 'Ad network', value: 'ad_network' },
        { text: 'Account ID', value: 'account_id' },
        { text: 'Account Num', value: 'account_number' },
        { text: 'Name', value: 'account_name' },
        { text: 'Mapped domain', value: 'domains_csv' },
        { text: 'Vertical', value: 'vertical' },
        { text: 'Country', value: 'country_iso2' },
        { text: 'Language', value: 'language_iso2' },
        { text: 'Domain type', value: 'domain_type' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      lsOptions: 'accounts_options',
      lsFilters: 'accounts_filters',
      accountsEndpoint: '/a/core/account-metadata',
      selectedAdNetworks: [],
      selectedCountries: [],
      selectedDomainTypes: [],
      selectedLanguages: [],
      selectedVerticals: [],
      accountNameSearch: '',
    }
  },

  mixins: [dataTablesMixin],

  computed: {
    activeCountries () {
      return this.$store.state.common.activeCountries
    },
    activeLanguages () {
      return this.$store.state.common.activeLanguages
    },
    activeVerticals () {
      return this.$store.state.core.activeVerticals
    },
    adNetworks () {
      return this.$store.state.core.adNetworks
    },
    domainTypes () {
      return this.$store.state.core.domainTypes
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    fetchData: function () {
      this.loading = true
      var myURL = this.accountsEndpoint + '?xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, ['actions'], ['id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // filter ad networks
      if (this.selectedAdNetworks.length > 0) {
        myURL += '&ad_network=' + this.selectedAdNetworks.join('|')
      }

      // filter verticals
      if (this.selectedVerticals.length > 0) {
        myURL += '&verticals_fk=' + this.selectedVerticals.join('|')
      }

      // filter countries
      if (this.selectedCountries.length > 0) {
        myURL += '&countries_fk=' + this.selectedCountries.join('|')
      }

      // filter languages
      if (this.selectedLanguages.length > 0) {
        myURL += '&languages_fk=' + this.selectedLanguages.join('|')
      }

      // filter domain types
      if (this.selectedDomainTypes.length > 0) {
        myURL += '&domain_types_fk=' + this.selectedDomainTypes.join('|')
      }

      // filter account name search
      if (this.accountNameSearch) {
        myURL += '&account_name=~' + this.accountNameSearch + '~'
      }

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    show: function () {
     let filterObj = {
        'selectedAdNetworks': this.selectedAdNetworks,
        'selectedVerticals': this.selectedVerticals,
        'selectedCountries': this.selectedCountries,
        'selectedLanguages': this.selectedLanguages,
        'selectedDomainTypes': this.selectedDomainTypes,
        'accountNameSearch': this.accountNameSearch
      }

      localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      this.options.page = 1;
      this.fetchData()
    },
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // if present, use local filters
    if (localStorage.getItem(this.lsFilters)) {
      let filters = JSON.parse(localStorage.getItem(this.lsFilters))
      if (filters['selectedAdNetworks']) { this.selectedAdNetworks = filters['selectedAdNetworks'] }
      if (filters['selectedVerticals']) { this.selectedVerticals = filters['selectedVerticals'] }
      if (filters['selectedCountries']) { this.selectedCountries = filters['selectedCountries'] }
      if (filters['selectedLanguages']) { this.selectedLanguages = filters['selectedLanguages'] }
      if (filters['selectedDomainTypes']) { this.selectedDomainTypes = filters['selectedDomainTypes'] }
      if (filters['accountNameSearch']) { this.accountNameSearch = filters['accountNameSearch'] }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>
